<template>
	
	<div class="outer-wrapper">
		<div id="top-nav">
			<router-link v-if="redirect" :to="redirect">
				<img src="@/assets/back-icon.png" alt="back" class="back-icon"/>
			</router-link>
			<img @click="back()" src="@/assets/back-icon.png" alt="back" class="back-icon"/>
		</div>

		<div class="inner-wrapper details-form">


			<div>
				<h1 class="header"> 
					Traveller Details
				</h1>

				<Loader :loading="isLoading"/>

				<form v-if="!isLoading" class="pax_entry">
					<div v-if="traveller_id == false"> <!-- Only new travellers should have this prefil option -->
						<button class="button prefill" @click.prevent="prefill()" v-if="!prefillUsed">Fill information from my profile</button>
						<button class="button prefill" :class="{ used: prefillUsed }" @click.prevent="" v-else-if="prefillUsed">Information prefilled from travel</button>
					</div>

					<span class="note">Please enter your details as they appear on your passport</span>

					<section>
						<label for="pax_first_name" class="label">Name</label>
						<input type="text" aria-describedby="pax_first_name_error" v-model="paxFirstName" id="pax_first_name" class="input" maxLength="50">
						<div id="pax_first_name_error" role="alert" v-if="submitted && errors.has('firstName')" class="invalid-feedback">{{ errors.get('firstName')[0] }}</div>


						<label for="pax_last_name" class="label">Last Name</label>
						<input type="text" aria-describedby="pax_last_name_error" v-model="paxLastName" id="pax_last_name" class="input" maxLength="100">
						<div id="pax_last_name_error" role="alert" v-if="submitted && errors.has('lastName')" class="invalid-feedback">{{ errors.get('lastName')[0] }}</div>

						<!-- Semantic HTML: Use fieldset and legend for better structure and accessibility -->
						<fieldset>
							<legend class="label">Gender</legend>
							<div class="gender">
								<div class="gender-option">
									<input aria-describedby="gender_error" type="radio" id="male" value="Male" v-model="paxGender" class="input">
									<span class="checkmark"></span>
									<label for="male">Male</label>
								</div>
								<div class="gender-option">
									<input aria-describedby="gender_error" type="radio" id="female" value="Female" v-model="paxGender" class="input">
									<span class="checkmark"></span>
									<label for="female">Female</label>
								</div>
							</div>
							<div role="alert" v-if="submitted && errors.has('gender')" class="invalid-feedback" id="gender_error">{{ errors.get('gender')[0] }}</div>
						</fieldset>
					</section>

					<section>
						<h2 class="sub-header">Contact details</h2>

						<label for="pax_email" class="label">Email</label>
						<input type="email" class="email input" v-model="paxEmail" id="pax_email" maxLength="100" aria-describedby="pax_email_error">
						<div role="alert" v-if="submitted && errors.has('email')" class="invalid-feedback" id="pax_email_error">{{ errors.get('email')[0] }}</div>

						<label for="pax_phone" class="label">Contact Number</label>
						<vue-tel-input 
							id="pax_phone"
							class="input"
							v-model="paxPhone"
							@validate="validatePhone"
							mode="international"
							maxLength="20"
							placeholder=""
							:autoDefaultCountry="true"
							:autoFormat="true"
							aria-describedby="pax_phone_error"
							:dropdownOptions="{
								disabled: false,
								showDialCodeInList: true,
								showDialCodeInSelection: false,
								showFlags: true,
								showSearchBox: false,
								tabindex: 0,
								width: '250px'
							}"
							:inputOptions="{
								placeholder: '',
							}"
						></vue-tel-input>
						<input type="tel" v-model="paxPhone" id="hidden_pax_phone" aria-hidden="true" style="display: none;">
						<div role="alert" v-if="submitted && errors.has('phone')" class="invalid-feedback" id="pax_phone_error">{{ errors.get('phone')[0] }}</div>

					</section>
					<section>
						<h2 class="sub-header">Passport Details</h2>

						<label class="label" for="dob">Date of Birth</label>
						<v-date-picker 
							class="datepicker"
							id="dob"
							v-model="paxDob"
							mode="date" 
							color="customblue"
							:max-date="new Date()"
							:popover="{ visibility: 'click' }"
							aria-describedby="dob_error"
						>
							<template v-slot="{ inputValue, inputEvents, isDragging }">
								<input
									class="input"
									:class="isDragging ? 'is-selecting' : ''"
									:value="inputValue"
									v-on="inputEvents"
								/>
							</template>
						</v-date-picker>
						<div id="dob_error" role="alert" v-if="submitted && errors.has('dob')" class="invalid-feedback number">{{ errors.get('dob')[0] }}</div>

						<label for="pax_passport" class="label">Passport Number</label>
						<input type="text" class="input" v-model="paxPassportNumber" id="pax_passport" maxLength="100" aria-describedby="pax_passport_error">
						<div id="pax_passport_error" role="alert" v-if="submitted && errors.has('passport_number')" class="invalid-feedback">{{ errors.get('passport_number')[0] }}</div>

						<label class="label" for="country">Country of Issue</label>
						<locationDropdown 
							class="locationpicker location"
							id="country"
							v-model="paxCountryOfIssue"
							placeholder=""
							locationType="countries"
							aria-describedby="country_error"
						/>
						<div id="country_error" role="alert" v-if="submitted && errors.has('issue')" class="invalid-feedback">{{ errors.get('issue')[0] }}</div>

						<label class="label" for="expiry">Expiry Date</label>
						<v-date-picker 
							class="datepicker"
							id="expiry"
							v-model="paxPassportExpiry"
							mode="date" 
							color="customblue"
							:min-date="new Date()"
							:popover="{ visibility: 'click' }"
							aria-describedby="expiry_error"
						>
							<template v-slot="{ inputValue, inputEvents, isDragging }">
								<input
									class="input"
									:class="isDragging ? 'is-selecting' : ''"
									:value="inputValue"
									v-on="inputEvents"
								/>
							</template>
						</v-date-picker>
						<div id="expiry_error" role="alert" v-if="submitted && errors.has('expiry')" class="invalid-feedback number">{{ errors.get('expiry')[0] }}</div>
						<div id="related_adult_error" role="alert" v-if="submitted && errors.has('related_adult')" class="invalid-feedback">{{ errors.get('related_adult')[0] }}</div>
					</section>
					<button class="button continue save" @click.prevent="save()" :class="{ disabled: !checkForm }">Save</button> <!-- Save to trip button not functional yet (no custom trips) -->
				</form>
			</div>
		</div>
	</div>
</template>

<style scoped>
.outer-wrapper {
	padding-bottom: 60px;
}
.button.save {
	display: block;
	margin: 0 auto;
}
.button.prefill {
	background: none;
	border: 1px solid #118AB2;
	color: #118AB2;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	display: block;
	margin: 0 auto;
	margin-top: 20px;
	margin-bottom: 20px;
}
.button.prefill.used {
	border-color: #00D79F;
	color: black;
}
</style>

<script>
	import { mapState, mapActions, mapGetters } from 'vuex';
	import router from '@/router';
	import account from '@/helpers/account.js';
	import helpers from '@/helpers/helpers.js';
	import { travellerService } from '@/services/travellerService.js';
	import locationDropdown from '@/components/locationDropdown.vue';
	import constants from '@/helpers/constants.js';
	import Loader from '@/components/Loader.vue';
	import track from '@/helpers/track.js';

	export default {
		name: 'travellerForm',
		data() {
			return {
				trip_id: this.$route.params.trip_id ? this.$route.params.trip_id : false,
				trip_item_id: this.$route.params.trip_item_id ? this.$route.params.trip_item_id : false,
				traveller_id: this.$route.params.traveller_id ? this.$route.params.traveller_id : false,
				submitted: false,
				data: null,

				paxFirstName: "",
				paxLastName: "",
				paxGender: "",
				paxEmail: "",
				paxPhone: "",
				paxPhoneData: {},
				paxPassportNumber: "",
				paxCountryOfIssue: "",
				paxPassportExpiry: "",
				paxDob: "",
				errors: new Map(),

				isLoading: false,
				error: "",

				redirect: this.$route.query.redirect ? this.$route.query.redirect : null,
			}
		},
		components: {
			locationDropdown,
			Loader
		},
		computed: {
			...mapState({
				flightSearch: state => state.flightsSearch, 
				basket: state => state.basket,
				account: state => state.account,
			}),
			paxIsInfant() {
				let paxDob = new Date(this.paxDob).valueOf();
				let infantDobLimit = new Date();
				infantDobLimit = infantDobLimit.setFullYear(infantDobLimit.getFullYear() - constants.PAX_INFANT_MAX_AGE).valueOf();
				
				if(paxDob > infantDobLimit) {
					return true;
				}
				return false;
			},
			checkForm() {
				this.errors = new Map();
				let firstNameErrors = [];
				if(this.paxFirstName == null || this.paxFirstName == "") {
					firstNameErrors.push('You must enter a first name.');
				}
				var hasNumber = /\d/;
				if(hasNumber.test(this.paxFirstName)) {
					firstNameErrors.push('Name must not contain numbers.');
				}
				let lastNameErrors = [];
				if(this.paxLastName == null || this.paxLastName == "") {
					lastNameErrors.push('You must enter a last name.');
				}
				if(hasNumber.test(this.paxLastName)) {
					lastNameErrors.push('Name must not contain numbers.');
				}
				// let genderErrors = [];
				// if(this.paxGender == null || this.paxGender == "") {
				// 	genderErrors.push('You must select a gender.');
				// }
				let emailErrors = [];
				// if(this.paxEmail == null || this.paxEmail == "") {
				// 	emailErrors.push('You must enter an email.');
				// }
				if(this.paxEmail != null && this.paxEmail != "" && !account.validEmail(this.paxEmail)) { // Only trigger this if not empty.
					emailErrors.push('Must be a valid email.');
				}
				let phoneErrors = [];
				// if(this.paxPhone == null || this.paxPhone == "") {
				// 	phoneErrors.push('You must enter a phone number.');
				// }
				if(this.paxPhone) { // Only trigger this if not empty.
					// Check it is under 20 characters
					if(this.paxPhone.length > 20) {
						phoneErrors.push('Must be a valid number.');
					}
					if(!this.paxPhoneData.valid) {  // Check the validation
						phoneErrors.push('Must be a valid number.');
					}
				}
			
				// let dobErrors = [];
				// if(this.paxDob == null || this.paxDob == "") {
				// 	dobErrors.push('You must enter your date of birth.');
				// }
				// let passportNumberErrors = [];
				// if(this.paxPassportNumber == null || this.paxPassportNumber == "") {
				// 	passportNumberErrors.push('You must enter your passport number.');
				// }
				// let countryOfIssueErrors = [];
				// if(this.paxCountryOfIssue == null || this.paxCountryOfIssue == "") {
				// 	countryOfIssueErrors.push('You must enter your country of issue.');
				// }
				// let expiryDateErrors = [];
				// if(this.paxPassportExpiry == null || this.paxPassportExpiry == "") {
				// 	expiryDateErrors.push('You must enter your expiry date.');
				// }

				// // No, these are NOT required (so the user can add another pax and come back)
				// let relatedAdultErrors = [];
				// if(this.paxIsInfant) {
				// 	if(!this.relatedAdult) {
				// 		relatedAdultErrors.push('You must selected a related adult.')
				// 	}
				// }

				if(firstNameErrors.length > 0) {
					this.errors.set('firstName', firstNameErrors);
				}
				if(lastNameErrors.length > 0) {
					this.errors.set('lastName', lastNameErrors);
				}
				// if(genderErrors.length > 0) {
				// 	this.errors.set('gender', genderErrors);
				// }
				if(emailErrors.length > 0) {
					this.errors.set('email', emailErrors);
				}
				if(phoneErrors.length > 0) {
					this.errors.set('phone', phoneErrors);
				}
				// if(dobErrors.length > 0) {
				// 	this.errors.set('dob', dobErrors);
				// }
				// if(passportNumberErrors.length > 0) {
				// 	this.errors.set('passport_number', passportNumberErrors);
				// }
				// if(countryOfIssueErrors.length > 0) {
				// 	this.errors.set('issue', countryOfIssueErrors);
				// }
				// if(expiryDateErrors.length > 0) {
				// 	this.errors.set('expiry', expiryDateErrors);
				// }
				// if(relatedAdultErrors.length > 0) {
				// 	this.errors.set('related_adult', relatedAdultErrors);
				// }
				if (!this.errors.size > 0) {
					return true;
				}
				return false;
				e.preventDefault();
			},
		},
		methods: {
			...mapActions({
				saveNewTraveller: "trip/saveNewTraveller",
				editTraveller: "trip/editTraveller",
				alertError: "alert/error",
				alertSuccess: "alert/success",
				fetchUserProfile: "account/fetchUserProfile"
			}),
			formatDate: (date) => helpers.formatDate(date),
			validatePhone: function (data) {
				this.paxPhoneData = data;
			},
			save() {
				this.submitted = true;
				if(this.checkForm) {
					this.isLoading = true;

					// Update this to ensure the previously saved data (fare etc) is maintained
					let pax_data = {
						firstName: this.paxFirstName,
						lastName: this.paxLastName,
						gender: this.paxGender,
						email: this.paxEmail,
						// phone: this.paxPhone,
						phone: {
							countryCallingCode: this.paxPhoneData.countryCallingCode ? this.paxPhoneData.countryCallingCode : "",
							nationalNumber: this.paxPhoneData.nationalNumber ? this.paxPhoneData.nationalNumber : "",
							fullNumber: this.paxPhoneData.number ? this.paxPhoneData.number : "",
						},
						dob: this.paxDob ? helpers.formatDateISO(new Date(this.paxDob)) : "",
						passportNumber: this.paxPassportNumber,
						countryOfIssue: this.paxCountryOfIssue,
						passportExpiry: this.paxPassportExpiry ? helpers.formatDateISO(new Date(this.paxPassportExpiry)) : "",
					};
					if(this.traveller_id == false) { // If pax ID not provided, create new pax.
						// Track event
						try {
							track.event('addTraveller', {
								travellerAddType: 'new traveller',
								tripID: this.trip_id,
								tripItemID: this.trip_item_id ? this.trip_item_id : "",
								travellerID: "",
								travellerNationality: this.paxCountryOfIssue ? this.paxCountryOfIssue.code : "",
							});
						} catch(e) {
							console.error(e);
							this.$rollbar.error("Tracking: " + e);
						}
						// If trip/item IDs provided, will assign them appropriately
						this.saveNewTraveller({ tripID: this.trip_id, tripItemID: this.trip_item_id, paxData: pax_data })
							.then(
								response => {
									this.alertSuccess("Traveller saved successfully")
									if(this.redirect) {
										router.push(this.redirect);
									} else if(this.trip_item_id) {
										router.push({
											name: "Manage Trip Item Travellers",
											params: {
												trip_id: this.trip_id,
												trip_item_id: this.trip_item_id
											}
										})
									} else if(this.trip_id) {
										router.push({
											name: "Manage Trip Travellers",
											params: {
												trip_id: this.trip_id
											}
										})
									} else {
										router.push({
											name: 'Friends',
										});
									}
									this.isLoading = false;
								},
								error => {
									this.isLoading = false;
									this.alertError(error)
									this.error = error;
								}
							);
					} else { // If traveller_id is provided, update existing pax
						// Track event
						try {
							track.event('editTraveller', {
								tripID: this.trip_id,
								tripItemID: this.trip_item_id ? this.trip_item_id : "",
								travellerID: this.traveller_id,
								travellerNationality: this.paxCountryOfIssue ? this.paxCountryOfIssue.code : "",
							});
						} catch(e) {
							console.error(e);
							this.$rollbar.error("Tracking: " + e);
						}
						this.editTraveller({ travellerID: this.traveller_id, paxData: pax_data, tripID: this.trip_id, tripItemID: this.trip_item_id })
							.then(
								response => {
									this.isLoading = false;
									this.alertSuccess("Traveller saved successfully")
									if(this.redirect) {
										router.push(this.redirect);
									} else if(this.trip_item_id) {
										router.push({
											name: "Manage Trip Item Travellers",
											params: {
												trip_id: this.trip_id,
												trip_item_id: this.trip_item_id
											}
										})
									} else if(this.trip_id) {
										router.push({
											name: "Manage Trip Travellers",
											params: {
												trip_id: this.trip_id
											}
										})
									} else {
										router.push({
											name: 'Friends',
										});
									}
									
								},
								error => {
									this.isLoading = false;
									this.alertError(error)
									this.error = error;
								}
							);
						}
					
				} else {
					// TODO: scroll to top (to show errors)
				}
			},
			back() {
				if(this.redirect) {
					router.push(this.redirect);
				} else if(this.trip_item_id) {
					router.push({
						name: "Manage Trip Item Travellers",
						params: {
							trip_id: this.trip_id,
							trip_item_id: this.trip_item_id
						}
					})
				} else if(this.trip_id) {
					router.push({
						name: "Manage Trip Travellers",
						params: {
							trip_id: this.trip_id
						}
					})
				} else {
					router.push({
						name: 'Friends',
					});
				}
			},
			async prefill() {
				this.isLoading = true;
				if(this.account.userProfile == null) {
					// Load existing profile details if they aren't already in state.
					await this.fetchUserProfile()
				}
				this.paxFirstName = this.account.userProfile.paxFirstName;
				this.paxLastName = this.account.userProfile.paxLastName;
				this.coverImage = this.account.userProfile.coverImage;
				this.profileImage = this.account.userProfile.profileImage;
				this.paxGender = this.account.userProfile.paxGender;
				this.paxEmail = this.account.user.email; // Use the email from the login account, not the profile
				this.paxPhone = this.account.userProfile.paxPhone;
				this.paxPassportNumber = this.account.userProfile.paxPassportNumber;
				this.paxCountryOfIssue = this.account.userProfile.paxCountryOfIssue;
				this.paxPassportExpiry = this.account.userProfile.paxPassportExpiry;
				this.paxDob = this.account.userProfile.paxDob;

				this.prefillUsed = true;

				this.isLoading = false;
			},
		},
		mounted() {
		},
		created() {
			if(this.traveller_id) { // Edit passenger version
				this.isLoading = true;
			
				// Load existing passenger details
				travellerService.fetchTraveller(this.traveller_id)
					.then(response => {
						this.paxFirstName = response.data.data.firstName;
						this.paxLastName = response.data.data.lastName;
						this.paxGender = response.data.data.gender;
						this.paxEmail = response.data.data.email;
						this.paxPhone = response.data.data.phone.fullNumber;
						this.paxDob = response.data.data.dob;
						this.paxPassportNumber = response.data.data.passportNumber;
						this.paxCountryOfIssue = response.data.data.countryOfIssue;
						this.paxPassportExpiry = response.data.data.passportExpiry;
					
						this.isLoading = false;
					});
				
			}
			
		}
	}
</script>

